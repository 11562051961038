import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

function guardMyroute(to, from, next) {
 var isAuthenticated= false;
  localStorage.getItem('LoggedUser') ? isAuthenticated = true : isAuthenticated= false;
  if(isAuthenticated){
    next(); // allow to enter route
  } else {
    next('/login'); // go to '/login';
  }
}


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
    //component: () => import(/* webpackChunkName: "payment-details" */ '../views/Expired.vue')
  },
  {
    path: '/login',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue') 
  },
  {
    path: '/otp-verification',
    name: 'OtpVerification',
    // route level code-splitting
    // this generates a separate chunk (otp-verification.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "otp-verification" */ '../views/OtpVerification.vue')
  },
  {
    path: '/member-registration',
    name: 'MembershipRegistration',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (member-registration.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "member-registration" */ '../views/MembershipRegistration.vue'),
    //props: true
  },
  {
    path: '/enroll-with-membership-id',
    name: 'EnrollWithMembershipId',
    // route level code-splitting
    // this generates a separate chunk (entol-with-id.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "enroll-with-id" */ '../views/EnrollWithId.vue')
  },
  {
    path: '/update-payment-details',
    name: 'UpdatePaymentDetails',
    // route level code-splitting
    // this generates a separate chunk (payment-details.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "payment-details" */ '../views/UpdatePaymentDetails.vue'),
    //props: true
  },
  {
    path: '/enrollment-expired',
    name: 'EnrollmentExpired',
    // route level code-splitting
    // this generates a separate chunk (payment-details.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "payment-details" */ '../views/Expired.vue')
  }

]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if(to.path !== '/enrollment-expired') {
//     next('/enrollment-expired')
//   } else {
//     next()
//   }
//   next()
  
// })



export default router
